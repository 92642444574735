import "./styles/Intro.css";
import React, { useState, useEffect } from "react";
import { submitCommunityUserForm } from "./HomeClient";
import { checkIfMobileView } from "../../common/ScreenSize";
import { IMG_CLASSNAME, LIST_OF_IMAGES, SMALL_IMG_CLASSNAME } from "./HomeConstants";
import { validate, isPhoneNumberInvalid, isEmailInvalid } from "./HomeValidationValues";
import CommunityFromSubmitModal from "./CommunityFromSubmitModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { sendEventToGTM } from "../../GoogleAnalytics/gtmManager";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Intro = () => {
    const query = useQuery();
    const [openModal, setOpenModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [referralCode, setReferralCode] = useState("");

    const handleClose = (event, reason) => {
        if (openModal) {
            setOpenModal(false);
        }
        setSubmitted(false);
        setValues({});
    };
    useEffect(() => {
        if (query.get("referral_code")) {
            setValues((values) => ({
                ...values,
                ["referralCode"]: query.get("referral_code"),
            }));
        }
    }, []);
    useEffect(() => {
        if (Object.keys(errors).length === 0 && submitted) {
            let data = {
                name: values.name,
                email: values.email,
                phone_number: values.phone,
                referralCode: values.referralCode ? values.referralCode : "",
            };
            setLoaded(true);
            sendEventToGTM({ event: "registration-form-submit" });
            submitCommunityUserForm(data, setLoaded, setOpenModal, setReferralCode, setErrors);
        } else {
            setSubmitted(false);
        }
    }, [errors]);

    const handleCommunityLeadsFormSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        setSubmitted(true);
        const validationErrors = validate(values);
        setErrors(validationErrors);
    };

    const handleLeadChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handlePhoneChange = (e) => {
        setValues((values) => ({
            ...values,
            ["phone"]: e,
        }));
    };
    const showGalleryImages = (
        IMAGES,
        upperImgClassName,
        middleImgClassName,
        lowerImgClassName
    ) => {
        return (
            <div className="gallery-column-images">
                <div className={upperImgClassName}>
                    <img src={IMAGES.UPPER_IMAGE} alt="" />
                </div>
                <div className={middleImgClassName}>
                    <img src={IMAGES.MIDDLE_IMAGE} alt="" />
                </div>
                <div className={lowerImgClassName}>
                    <img src={IMAGES.LOWER_IMAGE} alt="" />
                </div>
            </div>
        );
    };

    const getCommunityForm = () => {
        return (
            <div className="intro-form-wrapper">
                <h2>Join Now</h2>
                <form className="intro-form" onSubmit={handleCommunityLeadsFormSubmit}>
                    <input
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        value={values.name || ""}
                        onChange={handleLeadChange}
                    />
                    {errors.name && !values.name && (
                        <p className="community-page-form-item-error">{errors.name}</p>
                    )}
                    <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={values.email || ""}
                        onChange={handleLeadChange}
                    />
                    {errors.email && isEmailInvalid(values.email) && (
                        <p className="community-page-form-item-error">
                            {isEmailInvalid(values.email)}
                        </p>
                    )}
                    <PhoneInput
                        placeholder="WhatsApp Number"
                        inputStyle={{
                            height: "55px",
                            background: "var(--dark-mode-800, #20232a)",
                            width: "100%",
                            margin: "0.5vw 0vw",
                            fontSize: "1.1vw",
                        }}
                        country={"in"}
                        value={values.phone}
                        onChange={(phone) => handlePhoneChange(phone)}
                    />

                    {errors.phone && isPhoneNumberInvalid(values.phone) && (
                        <p className="community-page-form-item-error">
                            {isPhoneNumberInvalid(values.phone)}
                        </p>
                    )}
                    <input
                        name="referralCode"
                        type="text"
                        placeholder="Referral Code (if any)"
                        value={values.referralCode}
                        onChange={handleLeadChange}
                    />
                    {errors.userAlreadyRegistered && (
                        <p className="community-page-form-item-error user-reg-error">
                            {errors.userAlreadyRegistered}
                        </p>
                    )}
                    <div className="join-community-btn-wrapper">
                        <button type="submit">Join Community for Free</button>
                    </div>
                    {openModal && (
                        <CommunityFromSubmitModal
                            referralCode={referralCode}
                            handleClose={handleClose}
                            openModal={openModal}
                        />
                    )}
                    {loaded && (
                        <div className="register-form-loader">
                            <CircularProgress size={30} />
                        </div>
                    )}
                </form>

                <span className="email-wati-text">
                    We will send you the link via Email and WhatsApp. We have a No Spam Policy.
                </span>
            </div>
        );
    };

    return (
        <div className="intro-section-wrapper">
            <h1>Join the Community. Code Everyday.</h1>
            <p className="intro-section-desc">
                Consistency is what makes your perfect. Solve Problems everyday with us by joining
                the Community.
            </p>
            <div className="intro-gallery-form-wrapper">
                {checkIfMobileView() ? (
                    <></>
                ) : (
                    <div className="gallery-row-images">
                        {showGalleryImages(
                            LIST_OF_IMAGES.LEFT_IMAGE,
                            SMALL_IMG_CLASSNAME,
                            IMG_CLASSNAME,
                            IMG_CLASSNAME
                        )}
                        {showGalleryImages(
                            LIST_OF_IMAGES.SECOND_LEFT_IMAGE,
                            IMG_CLASSNAME,
                            IMG_CLASSNAME,
                            SMALL_IMG_CLASSNAME
                        )}
                    </div>
                )}
                {getCommunityForm()}
                {checkIfMobileView() ? (
                    <></>
                ) : (
                    <div className="gallery-row-images">
                        {showGalleryImages(
                            LIST_OF_IMAGES.SECOND_RIGHT_IMAGE,
                            IMG_CLASSNAME,
                            IMG_CLASSNAME,
                            SMALL_IMG_CLASSNAME
                        )}
                        {showGalleryImages(
                            LIST_OF_IMAGES.RIGHT_IMAGE,
                            SMALL_IMG_CLASSNAME,
                            IMG_CLASSNAME,
                            IMG_CLASSNAME
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export default Intro;
