import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DehazeIcon from "@mui/icons-material/Dehaze";
import logo from "../../resources/images/PpaLogo.svg";
import LinearGradientBtn from "../../common/styles/LinearGradientBtn";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { coursesDetails } from "./CourseListConstants";
import showMoreArrow from "../../resources/images/showMoreArrow.png";
import newSticker from "../../resources/images/NewSticker.svg";
import { courses } from "./CourseListConstants";
import { GTM_TRACKER } from "../../GoogleAnalytics/gtmConstants";

export default function TemporaryDrawer() {
    const navigate = useNavigate();
    const { loggedIn, initiateLogout } = useGlobalContext();

    const [showDropDown, setShowDropDown] = useState(false);

    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const getButtons = () => {
        return (
            <>
                {loggedIn ? (
                    <div
                        className="side-navigation-login-btn"
                        onClick={() => {
                            initiateLogout();
                        }}
                    >
                        <LinearGradientBtn name="Log Out" />
                    </div>
                ) : (
                    <div
                        onClick={() => {
                            window.open("https://renaissance.programmingpathshala.com/login");
                        }}
                        className={`${GTM_TRACKER.LOGIN} side-navigation-login-btn`}
                    >
                        <LinearGradientBtn name="Log In" />
                    </div>
                )}
            </>
        );
    };

    const getDropdownForMob = () => {
        return (
            <div className="drop-down-wrapper">
                {coursesDetails &&
                    coursesDetails.map((data, index) => (
                        <div
                            className="drop-down-menu"
                            onClick={() => {
                                data.clickable && window.open(data.courseLink);
                            }}
                            key={index}
                        >
                            <div className="course-name-wrapper">
                                <h3 className="course-name">{data.courseName}</h3>
                                {data.courseName === courses.RENAISSANCE ? (
                                    <></>
                                ) : (
                                    <img className="new-tag-img" src={newSticker} alt="" />
                                )}
                            </div>

                            <p className="course-description">{data.courseDescription}</p>
                            <div className="horizontal-line"></div>
                        </div>
                    ))}
            </div>
        );
    };

    const NavigationList = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="side-navigation-header-wrapper">
                <a target="_blank" rel="noreferrer" href="https://programmingpathshala.com/">
                    <img
                        className="ppa-logo-mob"
                        width={200}
                        height={35}
                        src={logo}
                        alt="PPA-logo"
                    />
                </a>
            </div>
            <div className="side-nagivation-list-mob">
                <p
                    className={`${GTM_TRACKER.EXPLORE_COURSES} mob-course-drop-down`}
                    onClick={() => setShowDropDown(!showDropDown)}
                >
                    Explore Courses <img width={15} height={8} src={showMoreArrow} />
                </p>
                {showDropDown && getDropdownForMob()}

                <a
                    className={GTM_TRACKER.SUCCESS_STORIES}
                    href="https://renaissance.programmingpathshala.com/crack-coding-interviews/stories"
                >
                    Success Stories
                </a>
                <a
                    className={GTM_TRACKER.HIRE_FROM_US}
                    href="https://programmingpathshala.com/hire-from-us#"
                >
                    Hire With Us
                </a>
                <a
                    className={GTM_TRACKER.CAMPUS_PROGRAM}
                    href="https://programmingpathshala.com/campus-program#"
                >
                    Campus Program
                </a>
                <a className={GTM_TRACKER.BLOGS} href="https://blogs.programmingpathshala.com/">
                    Blogs
                </a>
                <a className={GTM_TRACKER.EVENTS} href="https://events.programmingpathshala.com/">
                    Events
                </a>
            </div>
            <div className="side-nagivation-login-trail-btn">{getButtons()}</div>
        </Box>
    );

    return (
        <div>
            {["left"].map((anchor, index) => (
                <React.Fragment key={index}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <DehazeIcon sx={{ color: "#333" }} />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {NavigationList(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
