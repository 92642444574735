import React from "react";
import { Modal } from "@mui/material";
import closeIcon from "../../resources/images/blueCloseIcon.svg";
import shackHandImg from "../../resources/images/shake-hand.svg";
import "./styles/Intro.css";
import twitterIcon from "../../resources/images/twitter.svg";
import linkedinIcon from "../../resources/images/linkedin.svg";
import whatsappIcon from "../../resources/images/whatsapp.svg";
import { WhatsappShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

const CommunityFromSubmitModal = (props) => {
    const { openModal, handleClose, referralCode } = props;
    let communityUrl = "https://community.programmingpathshala.com/";
    let communityShareMessage = `I have joined Programming Pathshala's Free Community, and have committed to #CodeEveryday 👨‍💻 You can too, if you want to become a stellar coder ✨. Join using this link: https://community.programmingpathshala.com?referralCode=${referralCode}`;
    return (
        <Modal open={openModal} onClose={handleClose}>
            <div className="community-submitted-modal-wrapper">
                <div className="community-submit-modal">
                    <img
                        className="close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={props.handleClose}
                    />
                    <div className="community-submit-modal-greeting">
                        Thanks for registering for the community!
                    </div>
                    <img
                        src={shackHandImg}
                        className="shake-hand-img"
                        onClick={props && props.handleClose}
                    />

                    <p className="community-submit-modal-info">
                        You will receive the Community joining link via your <span>Email</span> and{" "}
                        <span>WhatsApp</span> shortly.
                    </p>
                    <p className="share-msg">
                        Share about the community with your network, and win rewards like boAts
                        Earphones, Laptop bag, and much more
                    </p>
                    <div className="share-icons-wrapper">
                        <TwitterShareButton title={communityShareMessage} url={communityUrl}>
                            <img className="share-icon-img" src={twitterIcon} />
                        </TwitterShareButton>
                        <LinkedinShareButton title={communityShareMessage} url={communityUrl}>
                            <img className="share-icon-img" src={linkedinIcon} />
                        </LinkedinShareButton>
                        <WhatsappShareButton title={communityShareMessage} url={communityUrl}>
                            <img className="share-icon-img" src={whatsappIcon} />
                        </WhatsappShareButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CommunityFromSubmitModal;
