import axios from "axios";

const submitCommunityUserForm = (data, setLoaded, setOpenModal, setReferralCode, setErrors) => {
    axios
        .post(process.env.REACT_APP_MISCELLANEOUS_URL + "/postCommunityUserDetails", data)
        .then((response) => {
            console.log(response);
            if (response && response.data && response.data.userReferralCode) {
                setReferralCode(response.data.userReferralCode);
            }
            setLoaded(false);
            setOpenModal(true);
        })
        .catch((error) => {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.userRegistered
            ) {
                setErrors({ userAlreadyRegistered: error.response.data.errorMessage });
            }
            console.log(error);
            setLoaded(false);
        });
};
export { submitCommunityUserForm };
