import { COURSE_DETAILS } from "./HomeConstants";
import arrowRight from "../../resources/images/arrow-right.svg";
import "./styles/ExploreCourses.css";
import SliderComponent from "../common/Slider";
import { sendEventToGTM } from "../../GoogleAnalytics/gtmManager";

const ExploreCourses = () => {
    const getCourseCard = (data, index) => {
        return (
            <a
                href={data.courseLink}
                className="course-card"
                key={index}
                onClick={() => {
                    sendEventToGTM({
                        event: "course-page-click",
                        course: `${data.gaCourseTracker}`,
                    });
                }}
            >
                <div className="course-card-img-wrapper">
                    <img src={data.courseBannerImg} alt="" />
                </div>
                <p className="course-card-desc">{data.courseDescription}</p>

                <div className="course-card-btn">
                    <p className="know-more-txt">Know More</p>
                    <div className={"arrow-right"}>
                        <img src={arrowRight} alt="" />
                    </div>
                </div>
            </a>
        );
    };
    return (
        <div className="explore-courses-wrapper">
            <h1>Explore our Courses</h1>
            <div className="courses-wrapper">
                <SliderComponent
                    slides={4}
                    cardDetails={COURSE_DETAILS}
                    cardComponent={getCourseCard}
                />
            </div>
        </div>
    );
};

export default ExploreCourses;
