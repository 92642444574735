const GTM_TRACKER = {
    REFER_NOW: "ga-refer-now-btn",
    SUBSCRIBE_YOUTUBE: "ga-subscribe-youtube-btn",
    JOIN_COMMUNITY: "ga-join-community-btn",
    REDEEM_NOW: "ga-redeem-now",
    FILL_FORM: "ga-fill-form",

    LOGIN: "ga-login-btn",
    SIGN_UP: "ga-sign-up-btn",
    EXPLORE_COURSES: "ga-explore-courses-btn",
    SUCCESS_STORIES: "ga-success-stories-btn",
    HIRE_FROM_US: "ga-hire-from-us-btn",
    CAMPUS_PROGRAM: "ga-campus-program-btn",
    BLOGS: "ga-blogs-btn",
    EVENTS: "ga-events-btn",
};

const gaCourseNameMap = {
    RENAISSANCE: "Renaissance",
    LOW_LEVEL_DESIGN: "Low-Level-Design",
    CPP: "Cpp",
    DSA_ESSENTIALS: "Dsa-Crash-Course",
    PYTHON: "Python",
    NODE_JS: "Node-Js",
    JAVA: "Java",
};

export { GTM_TRACKER, gaCourseNameMap };
