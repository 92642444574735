import { PPA_COMMUNITY_MEDIA_BUCKET_PREFIX } from "./HomeConstants";
import "./styles/ImproveProblemSolving.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { GTM_TRACKER } from "../../GoogleAnalytics/gtmConstants";
const ImproveProblemSolving = () => {
    const { windowWidth } = useWindowDimensions();

    const scrollToRegForm = () => {
        if (windowWidth > 600) {
            window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 50, left: 0, behavior: "smooth" });
        }
    };

    return (
        <div className="problem-solving-section-wrapper">
            <img
                className="group-coding-img"
                src={PPA_COMMUNITY_MEDIA_BUCKET_PREFIX + `groupCoding.png`}
                alt=""
            />
            <div>
                <h1>
                    Want to improve your Problem Solving? <br />{" "}
                    <span className="blue-text">Code Everyday</span> with us.{" "}
                </h1>
                <div className={`${GTM_TRACKER.JOIN_COMMUNITY} join-community-button-wrapper`}>
                    <button onClick={scrollToRegForm}>Join our Community</button>
                </div>
            </div>
        </div>
    );
};

export default ImproveProblemSolving;
