import { YOUTUBE_VIDEO_DETAILS } from "./HomeConstants";
import arrowRight from "../../resources/images/arrow-right.svg";
import "./styles/Youtube.css";
import "./styles/ExploreCourses.css";
import SliderComponent from "../common/Slider";
import { GTM_TRACKER } from "../../GoogleAnalytics/gtmConstants";
import { sendEventToGTM } from "../../GoogleAnalytics/gtmManager";

const YoutubeVideos = () => {
    const YoutubeCard = (data, index) => {
        return (
            <a
                onClick={() => {
                    sendEventToGTM({
                        event: "youtube-video-click",
                        youtube_video: `${data.gaVideoTracker}`,
                    });
                }}
                href={data.videoLink}
                className="youtube-card"
                key={index}
            >
                <div className="youtube-card-img-wrapper">
                    <img src={data.videoBanner} alt="" />
                </div>
            </a>
        );
    };
    return (
        <div className="youtube-videos-wrapper">
            <h1>Watch Free Videos on our YouTube Channel</h1>
            <div className="courses-wrapper">
                <SliderComponent
                    slides={2}
                    cardDetails={YOUTUBE_VIDEO_DETAILS}
                    cardComponent={YoutubeCard}
                />
            </div>
            <div className={`${GTM_TRACKER.SUBSCRIBE_YOUTUBE} subscribe-yt-wrapper`}>
                <button
                    onClick={() => {
                        window.open("https://www.youtube.com/@ProgrammingPathshala");
                    }}
                >
                    Subscribe to our Youtube Channel
                </button>
            </div>
        </div>
    );
};

export default YoutubeVideos;
