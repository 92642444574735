import React, { useState } from "react";
import { giftCardContents } from "./EarnCoinsConstants";
import "./styles/RewardSection.css";
import coinsIllustrator from "../../resources/images/coinsIllustrator.svg";
import { useLeaderBoardContext } from "../../context/LeaderBoardContext";
import { getReward } from "./LeaderBoardClient";
import { RewardConfirmationModal, RedeemModal } from "./LeaderBoardModals";
import { CircularProgress } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { GTM_TRACKER } from "../../GoogleAnalytics/gtmConstants";

const RewardsSection = () => {
    const [rewardConfirmationModal, setRewardConfirmationModal] = useState(false);
    const [redeemModal, setRedeemModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [redeemData, setRedeemData] = useState({});
    const [redeemSnackbar, setRedeemSnackbar] = useState(false);
    const [redeemMessage, setRedeemMessage] = useState("");
    const { userPoints, phoneNumber, setUserPoints } = useLeaderBoardContext();

    const handleRedeemClick = (coinsEarned, rewardName, rewardFormLink) => {
        if (userPoints < coinsEarned) {
            setRedeemSnackbar(true);
            setRedeemMessage(`You need ${coinsEarned} points to redeem this reward`);
            return;
        }
        let data = {
            pointsOfReward: coinsEarned,
            pointsEarned: userPoints,
            rewardName: rewardName,
            phoneNumber: phoneNumber,
            formLink: rewardFormLink,
        };
        setRedeemData(data);
        setRedeemModal(true);
    };
    const handleRedeemClose = () => {
        if (redeemModal) {
            setRedeemModal(false);
        }
    };
    const activateRedeemFlow = () => {
        setRedeemModal(false);
        setLoader(true);
        getReward(redeemData, setUserPoints, setRewardConfirmationModal, setLoader);
    };

    const userEligibleForRedeem = (coinsEarned) => {
        return userPoints >= coinsEarned;
    };

    const handleClose = (event, reason) => {
        if (rewardConfirmationModal) {
            setRewardConfirmationModal(false);
        }
    };
    const handleRedeemSnackbarClose = () => {
        setRedeemSnackbar(false);
    };
    const getErrorSnackBar = () => {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={redeemSnackbar}
                autoHideDuration={2000}
                onClose={handleRedeemSnackbarClose}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleRedeemSnackbarClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                message={redeemMessage}
            />
        );
    };
    const getGiftItemsCard = () => {
        return (
            <div className="refer-gift-cards-wrapper">
                {giftCardContents &&
                    giftCardContents.map((data, index) => (
                        <div className="refer-gift-card" key={index}>
                            <div className="gift-image-wrapper">
                                <img src={data.image} alt="" />
                            </div>
                            <div className="card-details-wrapper">
                                <p className="refer-card-desc"> {data.description}</p>
                                <div className="card-coin-box">
                                    <p>{data.coinsEarned}</p>
                                    <img src={coinsIllustrator} alt="" />
                                </div>
                            </div>

                            <button
                                className={`redeem-points-btn ${
                                    userEligibleForRedeem(data.coinsEarned)
                                        ? `${GTM_TRACKER.REDEEM_NOW} redeem-points-btn-enabled`
                                        : "redeem-points-btn-disabled"
                                }`}
                                onClick={() => {
                                    handleRedeemClick(
                                        data.coinsEarned,
                                        data.description,
                                        data.formLink
                                    );
                                }}
                            >
                                Redeem Now
                            </button>
                        </div>
                    ))}
            </div>
        );
    };

    return (
        <div className="earn-coins-wrapper">
            <div className="earn-coins-title-wrapper">
                <h3>Rewards</h3>
            </div>
            {getGiftItemsCard()}
            {redeemModal && (
                <RedeemModal
                    openModal={redeemModal}
                    handleClose={handleRedeemClose}
                    activateRedeemFlow={activateRedeemFlow}
                    redeemData={redeemData}
                />
            )}
            {rewardConfirmationModal && (
                <RewardConfirmationModal
                    openModal={rewardConfirmationModal}
                    redeemData={redeemData}
                />
            )}
            {loader && (
                <div className="reward-form-loader">
                    <CircularProgress size={60} />
                </div>
            )}
            {getErrorSnackBar()}
        </div>
    );
};

export default RewardsSection;
