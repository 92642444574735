import { gaCourseNameMap } from "../../GoogleAnalytics/gtmConstants";
const coursesDetails = [
    {
        courseKey: 1,
        courseName: "Renaissance",
        courseDescription:
            "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",

        courseLink: "https://renaissance.programmingpathshala.com/crack-coding-interviews",
        clickable: true,
        showNewIcon: false,
        gaCourseTracker: gaCourseNameMap.RENAISSANCE,
    },
    {
        courseKey: 2,
        courseName: "Low Level Design for Professionals",
        courseDescription:
            "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
        courseLink: "https://renaissance.programmingpathshala.com/low-level-design",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.LOW_LEVEL_DESIGN,
    },
    {
        courseKey: 3,
        courseName: "Data Structures & Algorithms Essentials",
        courseDescription:
            "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
        courseLink: "https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.DSA_ESSENTIALS,
    },
    // {
    //     courseKey: 4,
    //     courseName: "Become a Brilliant Front-End Engineer",
    //     courseDescription:
    //         "Master the skills of a front-end engineer and build modern & responsive website applications.",

    //     courseLink: "/become-a-frontend-engineer",
    //     clickable: true,
    //     showNewIcon: true,
    // },
    {
        courseKey: 5,
        courseName: "Begin with Programming in C++",
        courseLink: "https://renaissance.programmingpathshala.com/begin-with-c++",
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.CPP,
    },
    {
        courseKey: 6,
        courseName: "Become a Python Expert",
        courseDescription:
            "Become a Python pro and unlock the door to endless career opportunities",
        courseLink: "https://renaissance.programmingpathshala.com/begin-with-python",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.PYTHON,
    },
];

const courses = {
    RENAISSANCE: "Renaissance",
    LLD: "Low Level Design for Professionals",
    CPP: "begin with c++",
    PYTHON: "become a Python Expert",
    // REACT: "Become a brilliant Front-End Engineer",
};
export { coursesDetails, courses };
