import Header from "../Header/Header";
import Intro from "./Intro";
import CommunityPageSections from "./CommunityPageSections";
import ImproveProblemSolving from "./ImproveProblemSolving";
import Footer from "../Footer/Footer";
import "./styles/Home.css";
import ExploreCourses from "./ExploreCourses";
import YoutubeVideos from "./YoutubeVideos";

const Home = () => {
    return (
        <div>
            
            <div className="header-sticky">
                <Header />
            </div>
            <div className="home-page-container">
                <Intro />
                <CommunityPageSections />
                <YoutubeVideos />
                <ExploreCourses />
                <ImproveProblemSolving />
            </div>

            <Footer />
        </div>
    );
};

export default Home;
